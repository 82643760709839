
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import base from "@/api/base.js";
import * as Yup from "yup";

export default defineComponent({
  name: "create-api-key-modal",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    const validation = Yup.object().shape({
      brandNm: Yup.string().required("필수값입니다."),
      brandUseYn: Yup.boolean().required("필수값입니다.")
    });
    return {
      companys: {},
      brand: { cmp: {} },
      validation
    };
  },

  props: {},

  created() {
    base
      .getCompanyList()
      .then(res => {
        console.log(res);
        this.companys = res.data;
      })
      .catch(error => {
        console.log(error);
      });
  },

  methods: {
    async submitForm() {
      console.log(this.brand);
      await base
        .newBrand(this.brand)
        .then(res => {
          console.log(res);
          if (res.status == 201) {
            alert("신규 브랜드를 등록했습니다.");
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    }
  }
});

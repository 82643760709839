
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import NewBrandModal from "@/views/brands/NewBrandModal.vue";
import base from "@/api/base.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "brands",
  components: {
    NewBrandModal,
    Field,
    Form,
    ErrorMessage,
    VPagination,
  },

  data() {
    return {
      brands: [],
      detailbrand: { cmp: {} },
      searchcode: "cmp_nm",
      searchdata: "",
      status: "",
      page: 1,
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        brand_use_yn: this.status,
        page: this.page,
      };

      base
        .getBrandList(param)
        .then((res) => {
          console.log(res);
          this.brands = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    getData(id) {
      base
        .getBrand(id)
        .then((res) => {
          console.log(res);
          this.detailbrand = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editData() {
      base
        .updateBrand(this.detailbrand)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("브랜드 정보를 수정했습니다.");
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
});
